import React from 'react';
import Pusher from 'pusher-js';
import Timing from "./Timing";
import Messages from "./Messages";
import SessionInfo from "./SessionInfo";
import styles from './Timing.module.css'


export default class Dashboard extends React.Component {

    state = {
        channel: undefined,
        status: "waiting",
        installationId: undefined
    };

    pusher = {};

    constructor(props) {
        super(props);
        this.pusher = new Pusher('85290bf34a766013e3f5', {
            cluster: 'EU',
            encrypted: true
        });
    }


    componentDidMount() {
        const id = window.location.pathname.replace('/', '');
        let channel = this.pusher.subscribe(id);
        this.setState({
            ...this.state,
            channel: channel,
            installationId: id,
        });

        channel.bind('STANDINGS_UPDATE', data => {
            this.setState({
                ...this.state,
                status: "Last update received on " + new Date().toLocaleString(),
            });
        });

        channel.bind('NEW_SESSION_STARTED', data => {
            this.setState({
                ...this.state,
                status: "New session started, waiting for new data",
            });
        });

        channel.bind('DISCONNECTED', data => {
            this.setState({
                ...this.state,
                status: "Disconnected, livetiming stopped",
            });
        });
    }

    render() {
        return (<div className={styles.dashboard}>
            {
                this.state.channel ? this.renderLiveTiming() : <div/>
            }
        </div>)
    }

    renderLiveTiming() {
        return <div>
            <p className={styles.status}>{this.state.status}</p>
            <SessionInfo channel={this.state.channel}/>
            <Timing channel={this.state.channel} installationId={this.state.installationId}/>
            <Messages channel={this.state.channel}/>
        </div>;
    }
}
