import React from 'react';
import styles from './Timing.module.css'

export default class Messages extends React.Component {

    state = {
        messages: [],
    };

    componentDidMount() {
        this.props.channel.bind('STATUS_MESSAGE', data => {
            this.setState({
                ...this.state,
                messages: [data].concat(this.state.messages),
            });
        });

        this.props.channel.bind('NEW_SESSION_STARTED', data => {
            this.setState({
                ...this.state,
                messages: [],
            });
        });
    }

    render() {
        return this.state.messages ? this.renderList(this.state.messages) : <div/>;
    }

    renderList(messages) {
        return (<div>
            <h3>Messages</h3>
            <div className={styles.messages}>
                <ul>
                    {messages.map((message) => (
                        <li key={message.time}>
                            {message.status}
                        </li>
                    ))}
                </ul>
            </div>
        </div>)
    }
}
