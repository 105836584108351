import './App.css';
import Dashboard from "./timing/Dashboard";

function App() {
  return (
    <div className="App">
       <Dashboard/>
    </div>
  );
}

export default App;
