import React from 'react';
import styles from './TimingMobile.module.css'

export default class TimingMobile extends React.Component {

    render() {
        return this.props.rows ? this.renderTable(this.props.rows) : <div></div>;
    }

    renderTable(rows) {
        return <div className={styles.mobile}>
            {rows.map((row) => (
                <div key={row.number} className={styles.row}>
                    <div className={styles.position}>{row.p}</div>
                    <div className={styles.entry}>
                        <div className={styles.driver}>{row.driver}</div>
                        <div className={styles.car}>{row.car}</div>
                        <div className={!row.lastValid ? styles.lastInvalid : styles.last}>
                            Last: {row.last} - Fastest: {row.fastest}
                        </div>
                    </div>
                    <div className={styles.laps}>
                        {row.l} laps
                    </div>
                </div>
            ))}
        </div>
    }
}
