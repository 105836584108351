import React from 'react';
import styles from './Timing.module.css'

export default class TimingDesktop extends React.Component {

    render() {
        return this.props.rows ? this.renderTable(this.props.rows) : <div></div>;
    }

    renderTable(rows) {
        return <div className={styles.desktop}>
            <table className={styles.results}>
                <thead>
                <tr>
                    <th>P</th>
                    <th>#</th>
                    <th>Driver</th>
                    <th>Car</th>
                    <th>L</th>
                    <th></th>
                    <th>Last</th>
                    <th>Fastest</th>
                    <th>Theoretical</th>
                </tr>
                </thead>
                <tbody>
                {rows.map((row) => (
                    <tr key={row.number}>
                        <td>{row.p}</td>
                        <td>{row.n}</td>
                        <td>{row.driver}</td>
                        <td>{row.car}</td>
                        <td>{row.l}</td>
                        <td>{!row.lastValid ? (
                            <div className={styles.invalidLap} title="Last Lap was invalid"/>) : ("")}</td>
                        <td>{row.last}</td>
                        <td>{row.fastest}</td>
                        <td>{row.theo}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    }
}
