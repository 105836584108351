import React from 'react';
import TimingDesktop from "./TimingDesktop";
import TimingMobile from "./TimingMobile";

export default class Timing extends React.Component {

    state = {
        rows: [],
        installationId: undefined
    };

    componentDidMount() {
        let header = new Headers();
        header.append('X-Api-Key', 'TNb1AWFUHD5ISWQ1Ze7H4avH2ZwPNATm5nHdTolo');

        fetch("https://api.acc-recorder.be/live-timing/" + this.props.installationId, {
            headers: header,
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    ...this.state,
                    rows: data.rows,
                });
            })
            .catch((e) => {
                console.log("error retrieving initial timingdata" + e);
            })


        this.props.channel.bind('STANDINGS_UPDATE', data => {
            this.setState({
                ...this.state,
                rows: data,
            });
        });

        this.props.channel.bind('NEW_SESSION_STARTED', data => {
            this.setState({
                ...this.state,
                rows: [],
            });
        });
    }

    render() {
        return this.state.rows ? this.renderResults(this.state.rows) : <div></div>;
    }

    renderResults(rows) {
        return <div>
            <TimingDesktop rows={rows}/>
            <TimingMobile rows={rows}/>
        </div>
    }
}
