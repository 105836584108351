import React from 'react';
import styles from './Timing.module.css'

export default class SessionInfo extends React.Component {

    state = {
        sessionInfo: {},
    };

    componentDidMount() {
        this.props.channel.bind('SESSION_INFO_UPDATED', data => {
            this.setState({
                ...this.state,
                sessionInfo: data,
            })
        });

        this.props.channel.bind('NEW_SESSION_STARTED', data => {
            this.setState({
                ...this.state,
                sessionInfo: {},
            });
        });
    }

    render() {
        return this.state.sessionInfo ? this.renderSessionInfo(this.state.sessionInfo) : <div/>;
    }

    renderSessionInfo(sessionInfo) {
        let timeOfDay = sessionInfo.timeOfDay ? sessionInfo.timeOfDay.formattedTimeHM : '';
        let weather = '';
        if (sessionInfo.weather) {
            weather = sessionInfo.weather.weatherDescription + ' - ' + sessionInfo.weather.tempDescription;
        }
        return <div>
            <h2>{sessionInfo.trackName} - {sessionInfo.sessionType}</h2>
            <div className={styles.raceTime}>Race Time: {timeOfDay}</div>
            <div className={styles.weather}>Current weather: {weather}</div>
        </div>
    }
}
